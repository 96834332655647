export default {
      staking: {
        1: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
        5: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
      },
     ape: {
        1: "0x063284eF115c70b7b00336FE314Da5B4c5117355",
        5: "0x9A86d15DC93055685eC4457ddc9B6ba08584376A",
      },
      variable: {
        1: "0x063284eF115c70b7b00336FE314Da5B4c5117355",
        5: "0x9A86d15DC93055685eC4457ddc9B6ba08584376A",
      },
    standard: {
        1: "0xe559525118f9Ae71e2b21806B15332D17A00EBfF",
        5: "0xe559525118f9Ae71e2b21806B15332D17A00EBfF",
    },
  };
   